import {LandingPageGraphResponse} from "../../infrastructure/dto/graph-landing-page-response";
import {
    LandingContentSection,
    LandingPageViewModel,
    LandingPageViewModelProps,
    LatestArticle, LatestIngredient
} from "../../presentation/model/landing-page-view-model";
import util from "util";


export class GraphToLandingPageViewModelUseCase {
    private response: LandingPageGraphResponse;

    constructor(data: LandingPageGraphResponse) {
        this.response = data;
    }

    execute(): LandingPageViewModel {


        const props: LandingPageViewModelProps = {
            meta: this.map_meta(),
            content: {
                cta: this.map_content_cta(),
                sections: this.map_content_sections()
            },
            articles: {
                latest: this.map_latest_article()
            },
            ingredients: {
                latest: this.map_latest_ingredients()
            }

        }
        return LandingPageViewModel.create(props)
    }

    private filter_unpublished() {
        if (process.env.FLAVOUR === "production") {
            this.response.all_articles.articles = this.response.all_articles.articles
                .filter((article) => article.status === "published")
        }
    }


    private map_meta(): { description: string, title: string } {
        // filtered for language by graph query

        const localized_meta = this.response.page_content.pages_landing[0].translations[0]
        return {
            description: localized_meta.page_description,
            title: localized_meta.page_title
        }
    }

    private map_content_cta(): { body: string, button_label: string, headline: string } {
        // filtered for language by graph query
        const localized_cta = this.response.page_content.pages_landing[0].translations[0]
        return {
            body: localized_cta.content_intro,
            button_label: localized_cta.content_intro_button,
            headline: localized_cta.content_title
        }
    }

    private map_content_sections(): LandingContentSection[] {
        // filtered for language by graph query
        const localized_cta = this.response.page_content.pages_landing[0].translations[0]
        return localized_cta.content_sections.map((section) => {
            return {
                body: section.body,
                heading: section.heading
            }
        })
    }

    private map_latest_article(): LatestArticle {
        // Sorted by graphQl query
        const latest_article = this.response.all_articles.articles[0];
        const latest_article_localized = latest_article.translations[0];
        return {
            date_created: latest_article.date_created,
            date_updated: latest_article.date_updated,
            umbrella_slug: latest_article.umbrella_slug,
            slug: latest_article_localized.slug,
            headline: latest_article_localized.headline,
            intro: latest_article_localized.intro,
            subline: latest_article_localized.subline
        }
    }

    private map_latest_ingredients(): LatestIngredient[] {
        // Sorted by graphQl query
        return this.response.all_ingredients.ingredients.map((ingredient_aggregate) => {
            return ingredient_aggregate.translations.map((ingredient) => {
                const latest_ingredient: LatestIngredient = {
                    date_created: ingredient.date_created,
                    date_updated: ingredient.date_updated,
                    slug: ingredient_aggregate.umbrella_slug,
                    title: ingredient_aggregate.title,
                    intro: ingredient.intro ?? "",
                }
                return latest_ingredient;
            })
        }).flat()
    }
}