import React from "react";
import {LatestIngredient} from "../model/landing-page-view-model";
import {useI18next} from "gatsby-plugin-react-i18next";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

type LatestIngredientPreviewCardProps = {
    latestIngredient: LatestIngredient
}

export default function LatestIngredientPreviewCard({latestIngredient}: LatestIngredientPreviewCardProps) {


    const {t, language} = useI18next();

    const opener_images_response = useStaticQuery(graphql`
    {
      opener_images: allFile(
        filter: {sourceInstanceName: {eq: "media"}, extension: {in: ["jpg", "png"]}, relativeDirectory: {regex: "/ingredients/*.*/opener/"}}
      ) {
        edges {
          node {
            id
            sourceInstanceName
            name
            relativePath
            relativeDirectory
            publicURL
            ext
            extension
            dir
            root
            base
            absolutePath
            childImageSharp {
              id
              gatsbyImageData(width: 800, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  `)

    const opener_image_filtered = opener_images_response.opener_images.edges
        .filter((edge: any) => edge.node.relativeDirectory
            .includes(latestIngredient.slug)
        )[0]

    const opener_image_data = getImage(opener_image_filtered.node);

    return (
        <>
            <div className="p-0">
                <div className="max-w rounded overflow-hidden bg-white border ">
                    <div className="">
                        {opener_image_data &&
                            <GatsbyImage className=""
                                         alt={latestIngredient.title}
                                         image={opener_image_data}/>}
                    </div>
                    <div className="p-5">
                        <h2 className="text-2xl font-extrabold mb-5">{latestIngredient.title}</h2>
                        <p className="mb-5 text-gray-800">
                            {latestIngredient.intro}
                        </p>
                        {/* Read More */}
                        <div className="flex items-center">
                            <a
                                href={`/${language}/sushi/${latestIngredient.slug}`}
                                aria-label=""
                                className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
                            >
                                {t("LandingPage.Ingredients.Card.button_label")}
                                <svg
                                    className="inline-block w-3 ml-2"
                                    fill="currentColor"
                                    viewBox="0 0 12 12"
                                >
                                    <path
                                        d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}