import * as React from "react";
import {LatestArticle} from "../model/landing-page-view-model";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {NewspaperIcon} from "@heroicons/react/solid";
import {useI18next} from "gatsby-plugin-react-i18next";

type LatestArticlePreviewProps = {
    latestArticle: LatestArticle
}

export default function LatestArticlePreview({latestArticle}: LatestArticlePreviewProps) {

    const opener_images_response = useStaticQuery(graphql`
    {
      opener_images: allFile(
        filter: {sourceInstanceName: {eq: "media"}, extension: {in: ["jpg", "png"]}, relativeDirectory: {regex: "/articles/*.*/opener/"}}
      ) {
        edges {
          node {
            id
            sourceInstanceName
            name
            relativePath
            relativeDirectory
            publicURL
            ext
            extension
            dir
            root
            base
            absolutePath
            childImageSharp {
              id
              gatsbyImageData(width: 800, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  `)

    const {t, language} = useI18next();

    const opener_image_filtered = opener_images_response.opener_images.edges
        .filter((edge: any) => edge.node.relativeDirectory
            .includes(latestArticle.umbrella_slug)
        )[0]

    const opener_image_data = getImage(opener_image_filtered.node);

    return (
        <>
        <div className="flex flex-col max-w-screen-lg overflow-hidden bg-white border rounded shadow-sm lg:flex-row sm:mx-auto">

            {/* Image */}
            <div className="relative lg:w-1/2">
                {opener_image_data &&
                    <GatsbyImage className="object-cover w-full lg:absolute h-40 sm:h-80 lg:h-full"
                                 alt={latestArticle.subline}
                                 image={opener_image_data}/>}
                <svg
                    className="absolute top-0 right-0 hidden h-full text-white lg:inline-block"
                    viewBox="0 0 20 104"
                    fill="currentColor"
                >
                    <polygon points="17.3036738 5.68434189e-14 20 5.68434189e-14 20 104 0.824555778 104"/>
                </svg>
            </div>


            <div className="flex flex-col justify-center p-8 bg-white lg:p-16 lg:pl-10 lg:w-1/2">
                <div className="flex mb-4">
                    <div className="self-center">
                        <NewspaperIcon className="h-4"/>
                    </div>
                    <div className="self-center">
                        <p className="inline-block px-2 py-px text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                            {t("LandingPage.Articles.Latest.brand_new")}
                        </p>
                    </div>
                </div>
                <h2 className="mb-3 text-3xl font-extrabold leading-none sm:text-4xl">
                    {latestArticle.headline}
                </h2>
                <p className="mb-5 text-gray-800">
                    {latestArticle.intro}
                </p>
                <div className="flex items-center">
                    <a
                        href={`/${language}/${latestArticle.slug}`}
                        aria-label=""
                        className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
                    >
                        {t("LandingPage.Articles.Latest.button_label")}
                        <svg
                            className="inline-block w-3 ml-2"
                            fill="currentColor"
                            viewBox="0 0 12 12"
                        >
                            <path
                                d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z"/>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
        </>
    )
}
