import * as React from "react"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {graphql} from 'gatsby';

import Layout from "../components/layout"
import LandingPage from "../modules/landing/presentation/page/landing-page";
import {
    GraphToLandingPageViewModelUseCase
} from "../modules/landing/application/usecase/graph-to-landing-page-view-model-use-case";
import LandingPageSeo from "../modules/landing/presentation/components/seo/landing-page-seo";


export const query = graphql`
query ($language: String!, $flavourPubLevel: [String]) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
  all_articles: directus {
    articles(
      limit:4
      filter: {
        status: {_in: $flavourPubLevel},
        translations: {languages_code: {locale: {_eq: $language}}}
      }
    ) {
      id
      umbrella_slug
      internal_title
      status
      category
      date_created
      date_updated
      sort
      translations(
        sort: "-date_created"
        filter: {
            languages_code: {locale: {_eq: $language}},
            status: {_in: $flavourPubLevel}
        }
      ) {
        status
        slug
        headline
        subline
        intro
        keywords
        article_sections
        languages_code {
          locale
        }
      }
    }
  }
  all_ingredients: directus {
    ingredients(
      sort: "-date_updated"
      filter: {translations: {languages_code: {locale: {_eq: $language}}}}
      limit: 3
    ) {
      id
      date_created
      date_updated
      status
      umbrella_slug
      title
      translations(
        sort: "-date_updated"
        filter: {
            languages_code: {locale: {_eq: $language}},
            status: {_in: $flavourPubLevel}
        }
      ) {
        date_created
        date_updated
        status
        id
        intro
        meta_title
        meta_description
        languages_code {
          locale
        }
      }
    }
  }
  page_content: directus {
    pages_landing(
      filter: {translations: {languages_code: {locale: {_eq: $language}}}}
    ) {
      id
      translations(filter: {languages_code: {locale: {_eq: $language}}}) {
        languages_code {
          code
          name
          locale
        }
        page_title
        page_description
        page_keywords
        content_title
        content_subtitle
        content_intro
        content_intro_button
        content_sections
      }
    }
  }
}
`

export default function IndexPage({data}: any) {
    const {t} = useTranslation();

    // UseCases
    const useCase = new GraphToLandingPageViewModelUseCase(data);
    const viewModel = useCase.execute();

    return (
        <>
            <LandingPageSeo viewModel={viewModel}/>
            <Layout>
                {/*<pre>{JSON.stringify(viewModel, null, 4)}</pre>*/}
                <LandingPage viewModel={viewModel}/>
            </Layout>
        </>
    )

}
