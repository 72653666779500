import {ViewModel} from "../../../shared/building_blocks/view_model";

export type LatestArticle = {
    date_created: Date,
    date_updated: Date | null,
    umbrella_slug: string,
    slug: string
    headline: string
    subline: string
    intro: string
};

export type LatestIngredient = {
    date_created: Date,
    date_updated: Date | null,
    slug: string,
    title: string,
    intro: string,
}

export type LandingContentSection = {
    heading: string
    body: string
}

export interface LandingPageViewModelProps {
    meta: {
        title: string,
        description: string
    }
    articles: {
        latest: LatestArticle
    }
    ingredients: {
        latest: LatestIngredient[]
    }
    content: {
        cta: {
            headline: string
            body: string
            button_label: string
        },
        sections: LandingContentSection[]
    }
}

export class LandingPageViewModel extends ViewModel<LandingPageViewModelProps> {

    private constructor(props: LandingPageViewModelProps) {
        super(props);
    }

    public static create(props: LandingPageViewModelProps): LandingPageViewModel {
        return new LandingPageViewModel({...props})
    }

}