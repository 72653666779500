import {useTranslation} from "gatsby-plugin-react-i18next";
import React from "react";
import {LandingPageViewModel} from "../model/landing-page-view-model";
import LatestArticleComponent from "../components/latest-article-preview";
import LatestIngredientPreviewCard from "../components/latest-ingredient-preview-card";
import parse from "html-react-parser";

export interface LandingPageProps {
    viewModel: LandingPageViewModel
}

export default function LandingPage({viewModel}: LandingPageProps) {
    const {t} = useTranslation();

    return (
        <>
            <div className="layout-containerized">
                <div className="flex flex-col py-3 sm:py-8">


                    <LatestArticleComponent latestArticle={viewModel.props.articles.latest}/>

                    <div>
                        <h1 className="text-3xl font-bold mt-12 mb-2">{t('LandingPage.Ingredients.heading')}</h1>

                        <div className="grid grid-cols-12 gap-4 justify-evenly">
                            {viewModel.props.ingredients.latest.map((ingredient, index) => {
                                return (
                                    <div className="col-span-12 sm:col-span-4" key={index}>
                                        <LatestIngredientPreviewCard latestIngredient={ingredient}/>
                                    </div>
                                )
                            })}
                        </div>

                    </div>


                    {/* Content */}
                    <div>
                        {viewModel.props.content.sections.map((section, index) => {
                            return (
                                <div key={index}>
                                    <h2 className="text-2xl font-bold mt-4 mb-2">{section.heading}</h2>
                                    {parse(section.body)}
                                </div>
                            )
                        })}
                    </div>

                    {/* CTA */}
                    <div className="flex flex-col my-5">
                        <h2 className="text-4xl font-bold mt-4 mb-2 self-center text-center">{viewModel.props.content.cta.headline}</h2>
                        <p className="self-center text-center font-light text-lg">{viewModel.props.content.cta.body}</p>

                        <div className="self-center my-5">
                            <a href={t("Site.sushi_ingredients_uri")}>
                            <button type="button"
                                    className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                                {viewModel.props.content.cta.button_label}
                            </button>
                            </a>
                        </div>

                    </div>


                </div>
            </div>
        </>
    )
}

