import {LandingPageViewModel} from "../../model/landing-page-view-model";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import React from "react";
import {useI18next} from "gatsby-plugin-react-i18next";
import metaPublisherJson from "../../../../shared/seo/presentation/seo-meta-publisher";

export interface LandingPageSeoProps {
    viewModel: LandingPageViewModel
}

export default function LandingPageSeo({viewModel}: LandingPageSeoProps) {
    const {language, t} = useI18next();
    const metaTags = metaPublisherJson();
    const url = `${t("Site.url")}/${language}`;

    const languageAlternates = [
        {
            hrefLang: 'de',
            href: t("Site.url") + "/de/"
        },
        {
            hrefLang: 'en',
            href: t("Site.url") + "/en/"
        }];

    return (
        <>
            <GatsbySeo
                language={language}
                title={viewModel.props.meta.title}
                description={viewModel.props.meta.description}
                languageAlternates={languageAlternates}
                metaTags={metaTags}
                canonical={url}
            />
        </>
    )
}